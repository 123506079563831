import React from "react";
import { SanityEcosystemMember } from "../../model/common";
import { imageUrlBuilder } from "../../utils/images";
import styles from "./EcosystemMemberGrid.module.scss";

interface EcosystemMemberGridProps {
  members: SanityEcosystemMember[];
}

const EcosystemMemberGrid = ({ members }: EcosystemMemberGridProps) => {
  const width = 240;
  const height = 72;
  return (
    <div className={styles.root}>
      {members.map(({ name, logo }) => (
        <img
          src={
            imageUrlBuilder
              .ignoreImageParams()
              .image(logo)
              .width(width)
              .height(height)
              .fit("fillmax")
              .bg("fff")
              .url()!
          }
          alt={name}
        />
      ))}
    </div>
  );
};

export default EcosystemMemberGrid;
