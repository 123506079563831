import { WindowLocation } from "@reach/router";
import { graphql } from "gatsby";
import React from "react";
import EcosystemMemberGrid from "../components/EcosystemMemberGrid/EcosystemMemberGrid";
import PageLayout from "../layouts/PageLayout/PageLayout";
import ScrollingNavLayout from "../layouts/ScrollingNavLayout/ScrollingNavLayout";
import {
  CommonContext,
  SanityCampaign,
  SanityEcosystemMember,
  SanityPage,
  SanityRawContent
} from "../model/common";
import { GlobalMenus } from "../model/menu";
import { SiteArea } from "../utils/analytics";

export const pageQuery = graphql`
  query AboutPage($_id: String!, $navLanguage: String) {
    menus: sanityGlobalConfig(language: { eq: $navLanguage }) {
      ...MenuData
    }
    page: sanityAboutPage(_id: { eq: $_id }) {
      _id
      _type
      title
      metaDescription
      socialTitle
      socialDescription
      socialImage {
        ...SanityImage
      }
      _rawContent(resolveReferences: { maxDepth: 7 })
      metaDescription
    }
    partners: allSanityEcosystemMember(
      filter: { foundingPartner: { eq: true } }
      sort: { order: ASC, fields: name }
    ) {
      nodes {
        name
        logo {
          ...SanityImage
        }
      }
    }
    campaigns: allSanityCampaign(
      filter: { activeSiteAreas: { in: "otherPages" } }
    ) {
      nodes {
        ...Campaign
      }
    }
  }
`;

const AboutPage = ({
  pageContext,
  data: { menus, page, partners, campaigns },
  location
}: AboutPageProps) => {
  const { title, _rawContent } = page;
  const customEmbeds = {
    foundingPartnerGrid: () => <EcosystemMemberGrid members={partners.nodes} />
  };
  return (
    <PageLayout
      siteArea={SiteArea.ABOUT}
      metadata={{
        title,
        page,
        alternates: pageContext.alternates
      }}
      menus={menus}
      strings={pageContext.strings}
      location={location}
    >
      <ScrollingNavLayout
        campaigns={campaigns.nodes}
        title={title}
        content={_rawContent}
        customEmbeds={customEmbeds}
        social={false}
      />
    </PageLayout>
  );
};

export default AboutPage;

interface AboutPageProps {
  data: {
    menus: GlobalMenus;
    page: SanityPage & { _rawContent: SanityRawContent };
    partners: { nodes: SanityEcosystemMember[] };
    campaigns: { nodes: SanityCampaign[] };
  };
  location: WindowLocation;
  pageContext: CommonContext;
}
